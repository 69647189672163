export function IsDefined(value) {
    return value !== null && value !== undefined;
}
export const debounceWrapper = (fn, delay) => {
    let timeout;
    return (args) => {
        if (timeout)
            clearTimeout(timeout);
        timeout = setTimeout(() => fn(args), delay);
    };
};
export const throttleWrapper = (fn, delay) => {
    let lastCall = 0;
    return (args) => {
        const now = new Date().getTime();
        if (now - lastCall >= delay) {
            fn(args);
            lastCall = now;
        }
    };
};
export const roundToMultipleOf = (n, multiple) => {
    let result = multiple;
    if (n > 0) {
        result = Math.ceil(n / multiple) * multiple;
    }
    else if (n < 0) {
        result = Math.floor(n / multiple) * multiple;
    }
    return result;
};
export const clamp = (n, min, max) => {
    let result = n;
    if (n < min) {
        result = min;
    }
    else if (n > max) {
        result = max;
    }
    return result;
};
export const formatAmount = (amount) => (typeof amount === 'number' ?
    `${amount.toLocaleString('en-US')}` :
    '');
export const isEqualShallow = (a, b) => {
    if (!IsDefined(a) && !IsDefined(b)) {
        return true;
    }
    return typeof a === 'object' &&
        typeof b === 'object' &&
        Object.keys(a).length === Object.keys(b).length &&
        Object
            .keys(a)
            .every(key => Object.prototype.hasOwnProperty.call(b, key) &&
            a[key] === b[key]);
};
export const maskCardNumber = (cardNumber) => {
    var _a;
    const lastChars = (_a = cardNumber.split(' ')) === null || _a === void 0 ? void 0 : _a[3];
    return `${'*'.repeat(7)}${lastChars || ''}`;
};
export const getPublicNameInitials = (publicName) => {
    const initials = publicName.trim().split(' ');
    if ((initials === null || initials === void 0 ? void 0 : initials.length) > 1)
        return `${initials[0][0].toUpperCase()}${initials[1][0].toLocaleUpperCase()}`;
    if (initials === null || initials === void 0 ? void 0 : initials.length) {
        if ((publicName === null || publicName === void 0 ? void 0 : publicName.length) > 1) {
            return `${publicName[0].toUpperCase()}${publicName[1].toUpperCase()}`;
        }
        return `${initials[0][0].toUpperCase()}`;
    }
    return '';
};
