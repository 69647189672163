import { useGetPopularListQuery } from '@store/slices/portfolio';
const QUOTATIONS = [{
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    }, {
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    },
    {
        valueToSell: 'USDT', valueToBy: 'BTC', percentage: 1.58, trend: 'down', price: '$ 25.882',
    },
    {
        valueToSell: 'USDT', valueToBy: 'ETH', percentage: 2.11, trend: 'up', price: '$ 1.489',
    }];
const useMarketplaceInfo = () => {
    const { isLoading, currentData } = useGetPopularListQuery(undefined);
    return {
        quotations: QUOTATIONS,
        portfolioList: isLoading ? [] : currentData,
    };
};
export default useMarketplaceInfo;
