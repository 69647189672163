const numberAbbreviation = (n) => Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
})
    .format(n)
    .toLocaleLowerCase();
const formatCurrency = (n) => Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 2,
})
    .format(n)
    .toLocaleLowerCase();
const numberAbbreviationTwoDigits = (n) => {
    let resultNumber = numberAbbreviation(n);
    if (n < 1000 && n % 1 === 0) {
        resultNumber += '.00';
    }
    return resultNumber;
};
const formatAmountTwoDigits = (n) => {
    const formatter = Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter
        .format(n)
        .toLocaleLowerCase();
};
const formatAmountTwoDigitsMinimum = (n) => {
    const formatter = Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
    });
    return formatter
        .format(n)
        .toLocaleLowerCase();
};
const numberToFixed2 = (n) => (Math.round((n + Number.EPSILON) * 100) / 100).toFixed(2);
const percentageDifference = (difference, withPlusSign = true, customPlusSign = '') => {
    let sign = '';
    // minus sign will already be infront of the number
    if (withPlusSign && difference > 0) {
        sign = customPlusSign || '+';
    }
    return `${sign}${numberToFixed2(difference)} %`;
};
const getInitials = (s) => s
    .split(' ')
    .map(w => w[0])
    .join('')
    .slice(0, 2);
// TODO: make name more meaningfull
const getNumberColor = (n, theme) => {
    if (n >= 0) {
        return theme.palette.success.main;
    }
    return theme.palette.error.main;
};
export { numberAbbreviation, numberAbbreviationTwoDigits, numberToFixed2, formatCurrency, percentageDifference, getInitials, getNumberColor, formatAmountTwoDigits, formatAmountTwoDigitsMinimum, };
