const calculatePercentage = (partialValue, totalValue) => {
    if (totalValue === 0) {
        return 0;
    }
    return Math.round((100 * partialValue) / totalValue);
};
// TODO: move other math related helpers here
export { 
// eslint-disable-next-line import/prefer-default-export
calculatePercentage, };
